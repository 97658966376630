<template>
  <BaseModal
    :is-open="isOpen"
    :close-on-overlay-click="closeOnOverlayClick"
    :with-button-close="withButtonClose"
    @close="close"
  >
    <template #header>
      <c-box
        display="flex"
        justify-content="center"
        margin="24px 0 0 0"
      >
        <CImage
          height="150px"
          object-fit="cover"
          :src="require('@/assets/images/illustration-2-raw.jpeg')"
          alt="Image"
        />
      </c-box>
    </template>
    <template #body>
      <c-box
        display="flex"
        flex-direction="column"
        align-items="center"
        px="24px"
        py="16px"
        text-align="center"
      >
        <c-text
          font-weight="700"
          :font-size="['18px','20px']"
          mb="16px"
        >
          Email Tidak Terdaftar!
        </c-text>
        <c-text
          font-weight="400"
          :font-size="['14px', '16px']"
          color="#555555"
        >
          Mohon maaf, email kamu tidak dapat ditemukan. Mohon untuk mengecek ulang email anda.
        </c-text>
      </c-box>
    </template>
    <template #footer>
      <c-box
        width="100%"
        px="24px"
        mb="24px"
      >
        <BaseButton
          color="primary"
          rounded="1000px"
          width="100%"
          @click="close"
        >
          Kembali
        </BaseButton>
      </c-box>
    </template>
  </BaseModal>
</template>

<script>
import BaseButton from '@/components/elements/base-button.vue'
import BaseModal from '@/components/elements/base-modal.vue'

export default {
  name: 'ModalErrorEmailNotRegistered',
  components: { BaseModal, BaseButton },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    closeOnOverlayClick: {
      type: Boolean,
      default: true,
    },
    withButtonClose: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
